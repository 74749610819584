const setUploadFilename = id => { 
    const inputElement = document.getElementById(id)
    const nameContainer = document.getElementById(`${id}--content`)
    const filename = inputElement.files[0].name
    nameContainer.innerHTML = filename !== '' ? filename : 'Kein Bild ausgewählt'
    console.log(filename)
}
const isValidName = (input) => { 
    if (input.value.length < 2) return false
    return true
}
const isValidFile = (input) => { 
    if (input.files[0]) return true
    return false
}

const displayError = (id, msg) => { 
    document.getElementById(id).innerHTML = msg
}



