const scrollElement = () => {
    const scrollElements = document.querySelectorAll('[data-speed]')
    scrollElements.forEach(element => {
        const speed = element.dataset.speed
        // console.log(window.getComputedStyle(element))
        element.style.transform = `translateY(${window.scrollY / speed}px)`
    });
}

if (document.querySelector('[data-speed]')) {
    window.addEventListener('scroll', scrollElement)
}



// Smooth scroll



const smoothScroll = () => { 
    const anchorLinks = document.querySelectorAll('a[href*="#"]:not([href="#"])')
    const headerHeight = document.getElementById('header--navigation').offsetHeight
    // console.log(headerHeight)
    anchorLinks.forEach(link => { 
        link.addEventListener('click', (e) => { 
            console.log(link)
            e.preventDefault()
            if (window.location.pathname.replace(/^\//, '') === link.pathname.replace(/^\//, '') && location.hostname === link.hostname) {
                var target = link.hash;
                target = target.length ? target : document.querySelector('[name=' + link.hash.slice(1) + ']');
                if (target.length) {
                    const targetOffset = document.querySelector(target).offsetTop
                    window.scrollTo({
                        top: targetOffset - headerHeight,
                        left: 0,
                        behavior: "smooth"
                    })
                  return false;
                }
              }

        })
    })
}
if( document.getElementById('home--header') ) window.addEventListener('load', smoothScroll)
