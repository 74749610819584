const openLightbox = (name, index) => {
    const modalContent = document.getElementById('modal--content')
    const images = document.querySelectorAll('.image img')
    const lightbox = `
        <div class="lightbox">
            <span class="close" onclick='closeLightbox()'>&times;</span>
            <div class="lightbox--content">
                ${ index > 0 ? `<span class="prev" onclick="openLightbox('View', ${index-1})"><img width="12" src="assets/img/icn-arrow-left.svg"></span>` : '' }
                <img src="${images[index].src}" alt="${name}">
                ${ index < (images.length - 1) ? `<span class="next" onclick="openLightbox('View', ${index+1})"><img width="12" src="assets/img/icn-arrow-right.svg"></span>` : '' }
            </div>            
        </div>
    `
    modalContent.classList.add('active')
    modalContent.innerHTML = lightbox
    
    console.log(index, index+1)
}

const closeLightbox = () => { 
    const modalContent = document.getElementById('modal--content')
    modalContent.innerHTML = ""
    modalContent.classList.remove('active')
}