const filterWinnerList = (bool, el) => { 
    
    // Gewinner ein und ausblenden
    const noWinners = document.querySelector('.winners:not(.winners--teilnahme)')
    const winners = document.querySelector('.winners--teilnahme')
    bool ? noWinners.classList.remove('active') : noWinners.classList.add('active')
    bool ? winners.classList.add('active') : winners.classList.remove('active')

    // Button aktivieren
    el.parentNode.querySelector('.active').classList.remove('active')
    el.classList.add('active')

    // Erste 3 Ergebnisse highlighten
    highlightFirstThree('.winners.active li')
}


const highlightFirstThree = (sel) => { 
    const allItems = document.querySelectorAll(sel)
    const results = []
    allItems.forEach(item => {
        item.classList.remove('award')
        item.classList.remove('first')
        item.classList.remove('second')
        item.classList.remove('third')
        item.style.display !== 'none' && results.length < 3 && results.push(item)
    })
    results.map(res => res.classList.add('award'))
    results[0].classList.add('first')
    results[1].classList.add('second')
    results[2].classList.add('third')
}

if (document.querySelector('.winners.active')) {
    highlightFirstThree('.winners li')
}


const filterWinnerItems = val => { 
    const highscoreListElements = document.querySelectorAll('ol.winners > li')
    highscoreListElements.forEach(item => { 
        const h4 = item.querySelector('h4').innerText
        const score = item.querySelector('p').innerText
        if (h4.toLowerCase().indexOf(val.toLowerCase()) > -1 || score.toLowerCase().indexOf(val.toLowerCase()) > -1) {
            item.style.display = ''
        } else { 
            item.style.display = "none"
        }
    })
}